<template>
  
  <ClientOnly>



      <div v-if="lightboxDiary" class="lb">
        <div class="scrl" ref="scrl">

          <iframe
            class="iframe"
            :src="lightboxDiary + '?light=1'">
          </iframe>
 
        </div>


        <div class="close-btn" @click="closeLightbox">
          <i class="icon-close"></i>
        </div>

        <div class="bglb" 
          @click="closeLightbox"
          ></div>

      </div>

  </ClientOnly>
</template>

<script setup>
 


const emits = defineEmits(['close']);

const { $noscroll } = useNuxtApp();
const { lightboxDiary, close} = useLightboxDiary();

const scrl = ref(null);

 

const closeLightbox = () => {
  close();
  emits('close');
};
 

 


onMounted(() => {
  
});

onUnmounted(() => {

});
 
 
</script>

<style scoped>
.lb {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;
}
.lb .scrl {
  overflow-y: auto;
  max-height: calc(100vh - 0px);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  gap: 1rem;

    z-index: 1;
    width: 100vw;
    height: 100vh;
    max-width: 1200px;
}
.bglb {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* background-color: rgb(0 0 0 / 92%); */
  background-color: rgb(0 0 0 / 60%);
}
.lc {
  position: relative;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
    height: 90vh; 
    min-height: 90vh;
    max-width: 1200px;
} 

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  font-size: 1.1rem;
  z-index: 1;
}
  

/* iframe */

iframe{
  z-index: 11;
  border: 0;
  width: 90vw;
  height: 100vh;
  max-width: 1200px;
}

/* toggler */

.toggler {
  position: absolute;
  z-index: 11111;
  top: calc(50% - 30px);
  font-size: 2rem;
  color: white;
  background-color: #0000005c;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  cursor: pointer;
}
.toggler.left {
  left: 20px;
}
.toggler.right {
  right: 20px;
}
.toggler i{
  margin-top: 4px;
}


@media (max-width: 768px) {
  .lc {
    min-height: auto;
    height: auto;
    width: 100%;
  }
  .lc img {
   
  }
  .lc iframe {
   aspect-ratio: 1/1;
  }
}


</style>
