import { ref } from "vue";



const lightboxDiary = ref(null)

export const useLightboxDiary = () => {
  const { $api } = useNuxtApp()
  const close = () => {    
    lightboxDiary.value = null
    return true; 
  }
  const open = (link) => {    
    lightboxDiary.value = link
    console.log(link);
    return true; 
  }
 
  return {lightboxDiary, close, open};
}

 